<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <form autocomplete="off">
      <div class="row">
        <CustomFileInput
          :className="'col-12'"
          @changeValue="product.mediaFile = $event.file"
          :defaultImg="
            fullPathFileFromServer(product.productImagePath, product.defaultImg)
          "
          :deleteFileStatus="
            deleteFileStatus &&
            !product.productImageIsDefault &&
            checkPrivilege(hasProductDeleteImage())
          "
          v-on:deleteFile="$emit('deleteFile')"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-fullCode`"
            :value="product.fullCode"
            v-on:changeValue="product.fullCode = $event"
            :title="$t('Products.code')"
            :imgName="'code.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-productNameAr`"
            :errors="errors_productNameAr"
            :value="product.productNameAr"
            v-on:changeValue="
              product.productNameAr = $event;
              $v.product.productNameAr.$touch();
            "
            :title="$t('Products.nameAr')"
            :imgName="'products.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-productNameEn`"
            :errors="errors_productNameEn"
            :value="product.productNameEn"
            v-on:changeValue="
              product.productNameEn = $event;
              $v.product.productNameEn.$touch();
            "
            :title="$t('Products.nameEn')"
            :imgName="'products.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-productNameUnd`"
            :value="product.productNameUnd"
            v-on:changeValue="product.productNameUnd = $event"
            :title="$t('Products.nameUnd')"
            :imgName="'products.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="`${id}-productDescriptionAr`"
            :errors="errors_productDescriptionAr"
            :value="product.productDescriptionAr"
            v-on:changeValue="
              product.productDescriptionAr = $event;
              $v.product.productDescriptionAr.$touch();
            "
            :title="$t('Products.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-productDescriptionEn`"
            :errors="errors_productDescriptionEn"
            :value="product.productDescriptionEn"
            v-on:changeValue="
              product.productDescriptionEn = $event;
              $v.product.productDescriptionEn.$touch();
            "
            :title="$t('Products.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-productDescriptionUnd`"
            :value="product.productDescriptionUnd"
            v-on:changeValue="product.productDescriptionUnd = $event"
            :title="$t('Products.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-productNotes`"
            :value="product.productNotes"
            v-on:changeValue="product.productNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-categoryToken`"
            :value="product.categoryToken"
            :options="categoryTokenOptions"
            v-on:changeValue="product.categoryToken = $event"
            :title="$t('Categories.select')"
            :imgName="'categories.svg'"
          />
          <CustomInputFloat
            :className="'col-md-6'"
            :id="`${id}-productPrice`"
            :value="product.productPrice"
            v-on:changeValue="product.productPrice = $event"
            :title="$t('price')"
            :imgName="'money.svg'"
          />
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../../../../utils/functions";
import {
  isValidNameAr,
  isValidNameEn,
  isValidTextAr,
  isValidTextEn,
} from "./../../../../../utils/validationHelper";
import PreLoader from "./../../../../../components/general/PreLoader.vue";
import CustomFileInput from "./../../../../../components/general/CustomFileInput.vue";
import CustomInput from "./../../../../../components/general/CustomInput.vue";
import CustomInputFloat from "./../../../../../components/general/CustomInputFloat.vue";
import CustomSelectBox from "./../../../../../components/general/CustomSelectBox.vue";
import TextArea from "./../../../../../components/general/TextArea.vue";
import { hasProductDeleteImage } from "./../../../../../utils/privilegeHelper";
import { checkPrivilege } from "./../../../../../utils/functions";
import { getCategoriesDialog } from "./../../../../../utils/dialogsOfApi";
import generalMixin from "./../../../../../utils/generalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    PreLoader,
    CustomFileInput,
    CustomInput,
    CustomInputFloat,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      categoryTokenOptions: [],
    };
  },
  props: {
    product: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  validations: {
    product: {
      productNameAr: { required, isValidNameAr },
      productNameEn: { required, isValidNameEn },
      productDescriptionAr: { isValidTextAr },
      productDescriptionEn: { isValidTextEn },
    },
  },
  computed: {
    errors_productNameAr() {
      let errors = [];
      if (this.$v.product.productNameAr.$error) {
        if (
          !this.$v.product.productNameAr.required &&
          !this.$v.product.productNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.product.productNameAr.required &&
          !this.$v.product.productNameAr.isValidNameAr
        )
          errors.push(this.$t("validation.nameIsRequiredInAr"));
      }
      return errors;
    },
    errors_productNameEn() {
      let errors = [];
      if (this.$v.product.productNameEn.$error) {
        if (
          !this.$v.product.productNameAr.required &&
          !this.$v.product.productNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.product.productNameEn.required &&
          !this.$v.product.productNameEn.isValidNameEn
        )
          errors.push(this.$t("validation.nameIsRequiredInEn"));
      }
      return errors;
    },
    errors_productDescriptionAr() {
      let errors = [];
      if (this.$v.product.productDescriptionAr.$error) {
        if (!this.$v.product.productDescriptionAr.isValidTextAr)
          errors.push(this.$t("validation.fieldIsRequiredInAr"));
      }
      return errors;
    },
    errors_productDescriptionEn() {
      let errors = [];
      if (this.$v.product.productDescriptionEn.$error) {
        if (!this.$v.product.productDescriptionEn.isValidTextEn)
          errors.push(this.$t("validation.fieldIsRequiredInEn"));
      }
      return errors;
    },
  },
  methods: {
    async getCategoriesDialog() {
      this.isLoading = true;
      this.categoryTokenOptions = await getCategoriesDialog();
      this.isLoading = false;
    },
    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        ((this.$v.product.productNameAr.required &&
          this.$v.product.productNameAr.isValidNameAr) ||
          (this.$v.product.productNameEn.required &&
            this.$v.product.productNameEn.isValidNameEn)) &&
        this.$v.product.productDescriptionAr.isValidTextAr &&
        this.$v.product.productDescriptionEn.isValidTextEn
      ) {
        this.$emit("submitForm");
      }
    },
    checkPrivilege,
    hasProductDeleteImage,
    fullPathFileFromServer,
  },
  created() {
    this.getCategoriesDialog();
  },
};
</script>
