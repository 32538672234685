export default class UserProfile {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userGeneralNotes = "";
    this.userLocalJobToken = "";
    this.userLocalJobNameCurrent = "";
    this.userGenderToken = "";
    this.userGenderNameCurrent = "";
    this.userReligionToken = "";
    this.userReligionNameCurrent = "";
    this.userEducationalStageToken = null;
    this.educationalStageInfoData = null;
    this.dailyPurchaseLimit = 0;
    this.monthlyPurchaseLimit = 0;
  }
  fillData(data) {
    if (data) {
      this.userGeneralNotes = data.userGeneralNotes ?? "";
      this.userLocalJobToken = data.userLocalJobToken ?? "";
      this.userLocalJobNameCurrent = data.userLocalJobNameCurrent ?? "";
      this.userGenderToken = data.userGenderToken ?? "";
      this.userGenderNameCurrent = data.userGenderNameCurrent ?? "";
      this.userReligionToken = data.userReligionToken ?? "";
      this.userReligionNameCurrent = data.userReligionNameCurrent ?? "";
      this.userEducationalStageToken = data.userEducationalStageToken ?? null;
      this.educationalStageInfoData = data.educationalStageInfoData ?? null;
      this.dailyPurchaseLimit = data.dailyPurchaseLimit ?? 0;
      this.monthlyPurchaseLimit = data.monthlyPurchaseLimit ?? 0;
    } else {
      this.setInitialValue();
    }
  }
}
