import { fullPathFileFromServer } from "./functions";
import store from "./../store";
import i18n from "./../i18n";
import { STATUS } from "./constants";
import { ACTIVATION_TYPE } from "./constantLists";
const ACTIVATION_TYPE_TOKENS = ACTIVATION_TYPE.Active;

//#region Categories
import Categories from "./../models/settings/settingsOthers/categories/Categories";
import apiCategory from "./../api/settings/settingsOthers/categories";

export const getCategoriesDialog = async (params = {}) => {
  let categories = new Categories();
  categories.filterData.activationTypeTokens = ACTIVATION_TYPE_TOKENS;
  let options = [];
  try {
    //#region Filter
    categories.filterData.userToken = params.userToken ?? "";
    //#endregion Filter

    const response = await apiCategory.getDialog(categories.filterData);
    const itemsData = response.data.itemsData;
    if (response.data.status == STATUS.SUCCESS) {
      for (let item in itemsData) {
        options.push({
          value: itemsData[item]["itemToken"],
          text: itemsData[item]["itemName"],
          image: fullPathFileFromServer(
            itemsData[item]["itemImagePath"],
            categories.category.defaultImg
          ),
        });
      }
    } else if (response.data.status == STATUS.INVALID_TOKEN) {
      store.dispatch("logoutUser", response.data.msg);
    } else {
      options.push({
        value: "",
        text: response.data.msg,
        image: categories.category.defaultImg,
      });
    }
  } catch (error) {
    options.push({
      value: "",
      text: i18n.t("errorCatch"),
      image: categories.category.defaultImg,
    });
  }
  return options;
};
//#endregion Categories

//#region EstablishmentRoles
import EstablishmentRoles from "./../models/settings/establishmentRoles/EstablishmentRoles";
import apiEstablishmentRole from "./../api/settings/establishmentRole";
import { USER_TYPE } from "./../utils/constantLists";

export const getEstablishmentRolesDialog = async (modelName) => {
  let establishmentRoles = new EstablishmentRoles(modelName);
  establishmentRoles.filterData.activationTypeTokens = ACTIVATION_TYPE_TOKENS;
  let options = [];
  try {
    //#region filter
    let userTypeToken = "";
    switch (modelName) {
      case "adminsEgyptEstablishmentsRole":
        userTypeToken = USER_TYPE.AdminsEgypt;
        break;
      case "masterAdminEstablishmentsRole":
        userTypeToken = USER_TYPE.MasterAdmin;
        break;
      case "employeeEstablishmentsRole":
        userTypeToken = USER_TYPE.Employee;
        break;
      case "studentEstablishmentsRole":
        userTypeToken = USER_TYPE.Student;
        break;
      case "supplierEstablishmentsRole":
        userTypeToken = USER_TYPE.Supplier;
        break;
      case "parentEstablishmentsRole":
        userTypeToken = USER_TYPE.Parent;
        break;
      case "clientEstablishmentsRole":
        userTypeToken = USER_TYPE.Client;
        break;
    }

    establishmentRoles.filterData.userTypeToken = userTypeToken;

    //#endregion filter
    const response = await apiEstablishmentRole.getDialog(
      establishmentRoles.filterData
    );
    const itemsData = response.data.itemsData;
    if (response.data.status == STATUS.SUCCESS) {
      for (let item in itemsData) {
        options.push({
          value: itemsData[item]["itemToken"],
          text: itemsData[item]["itemName"],
          image: fullPathFileFromServer(
            itemsData[item]["itemImagePath"],
            establishmentRoles.establishmentRole.defaultImg
          ),
        });
      }
    } else if (response.data.status == STATUS.INVALID_TOKEN) {
      store.dispatch("logoutUser", response.data.msg);
    } else {
      options.push({
        value: "",
        text: response.data.msg,
        image: establishmentRoles.establishmentRole.defaultImg,
      });
    }
  } catch (error) {
    options.push({
      value: "",
      text: i18n.t("errorCatch"),
      image: establishmentRoles.establishmentRole.defaultImg,
    });
  }
  return options;
};
//#endregion EstablishmentRoles

//#region Users
import Users from "./../models/users/Users";
import apiUser from "./../api/user";

export const getUsersDialog = async (params) => {
  let modelName = params.modelName || "";
  let users = new Users(modelName);
  users.filterData.activationTypeTokens = ACTIVATION_TYPE_TOKENS;
  let options = [];
  try {
    users.filterData.userTypeToken = params.userTypeToken || "";
    users.filterData.userActivityTypeToken = params.userActivityTypeToken ?? "";
    users.filterData.educationalGroupToken = params.educationalGroupToken ?? "";

    const response = await apiUser.getDialog(users.filterData);
    const itemsData = response.data.itemsData;
    if (response.data.status == STATUS.SUCCESS) {
      for (let item in itemsData) {
        options.push({
          value: itemsData[item]["itemToken"],
          text: itemsData[item]["itemName"],
          image: fullPathFileFromServer(
            itemsData[item]["itemImagePath"],
            users.user.defaultImg
          ),
        });
      }
    } else if (response.data.status == STATUS.INVALID_TOKEN) {
      store.dispatch("logoutUser", response.data.msg);
    } else {
      options.push({
        value: "",
        text: response.data.msg,
        image: users.user.defaultImg,
      });
    }
  } catch (error) {
    options.push({
      value: "",
      text: i18n.t("errorCatch"),
      image: users.user.defaultImg,
    });
  }
  return options;
};

export const getUsersDialogCustomized = async (params) => {
  let modelName = params.modelName || "";
  let users = new Users(modelName);
  users.filterData.activationTypeTokens = ACTIVATION_TYPE_TOKENS;
  let error = null;
  let options = [];
  try {
    users.filterData.userTypeToken = params.userTypeToken || "";
    users.filterData.notInPlaceToken = params.notInPlaceToken ?? "";

    const response = await apiUser.getAll(users.filterData);
    const itemsData = response.data.usersData;
    if (response.data.status == STATUS.SUCCESS) {
      for (let item in itemsData) {
        options.push({
          value: itemsData[item]["userToken"],
          userNameCurrent: itemsData[item]["userNameCurrent"],
          phone: itemsData[item]["userPhoneWithCC"],
          fullCode: itemsData[item]["fullCode"],
          userName: itemsData[item]["userName"],
          image: fullPathFileFromServer(
            itemsData[item]["userImagePath"],
            users.user.defaultImg
          ),
        });
      }
    } else if (response.data.status == STATUS.INVALID_TOKEN) {
      store.dispatch("logoutUser", response.data.msg);
    } else {
      error = response.data.msg;
    }
  } catch (e) {
    error = i18n.t("errorCatch");
  }
  return [options, error];
};
//#endregion Users

//#region EducationalStages
import EducationalStages from "./../models/settings/settingsOthers/educationalStages/EducationalStages";
import apiEducationalStage from "./../api/settings/settingsOthers/educationalStages";

export const getEducationalStagesDialog = async (params = {}) => {
  let educationalStages = new EducationalStages();
  educationalStages.filterData.activationTypeTokens = ACTIVATION_TYPE_TOKENS;
  let options = [];
  try {
    //#region Filter
    educationalStages.filterData.userToken = params.userToken ?? "";
    //#endregion Filter

    const response = await apiEducationalStage.getDialog(
      educationalStages.filterData
    );
    const itemsData = response.data.itemsData;
    if (response.data.status == STATUS.SUCCESS) {
      for (let item in itemsData) {
        options.push({
          value: itemsData[item]["itemToken"],
          text: itemsData[item]["itemName"],
          image: fullPathFileFromServer(
            itemsData[item]["itemImagePath"],
            educationalStages.educationalStage.defaultImg
          ),
        });
      }
    } else if (response.data.status == STATUS.INVALID_TOKEN) {
      store.dispatch("logoutUser", response.data.msg);
    } else {
      options.push({
        value: "",
        text: response.data.msg,
        image: educationalStages.educationalStage.defaultImg,
      });
    }
  } catch (error) {
    options.push({
      value: "",
      text: i18n.t("errorCatch"),
      image: educationalStages.educationalStage.defaultImg,
    });
  }
  return options;
};

//#endregion EducationalStages
