import BaseEntityModelFilter from "./../general/BaseEntityModelFilter";
import store from "./../../store";
import { getLanguage } from "./../../utils/functions";
import { LANGUAGES } from "./../../utils/constants";
import i18n from "./../../i18n";

let language = getLanguage();
export default class UsersFilter extends BaseEntityModelFilter {
  constructor(modelName) {
    super();
    this.setInitialValue(modelName);
  }
  setInitialValue(modelName = "") {
    switch (language) {
      case LANGUAGES.arEG:
        this.itemName = `${i18n.t("code")}: {fullCode} - ${i18n.t(
          "name"
        )}: {userNameAr}`;
        break;
      case LANGUAGES.enUS:
        this.itemName = `${i18n.t("code")}: {fullCode} - ${i18n.t(
          "name"
        )}: {userNameEn}`;
        break;
    }

    try {
      this.activationTypeTokens =
        store.getters.generalSetting[modelName].activationTypeTokens;
    } catch (error) {
      this.activationTypeTokens = [];
    }
    this.establishmentRoleDataInclude = false;
    this.userProfileDataInclude = false;
    this.userToken = "";
    this.userLocalJobToken = "";
    this.userGenderToken = "";
    this.userReligionToken = "";
    this.userEducationalStageToken = "";
    this.blackListOfProductToken = "";
    this.whiteListOfProductToken = "";
    // added by me
    this.userTypeToken = "";
    this.token = "";
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.establishmentRoleDataInclude =
        data.establishmentRoleDataInclude ?? false;
      this.userProfileDataInclude = data.userProfileDataInclude ?? false;
      this.userToken = data.userToken ?? "";
      this.userLocalJobToken = data.userLocalJobToken ?? "";
      this.userGenderToken = data.userGenderToken ?? "";
      this.userReligionToken = data.userReligionToken ?? "";
      this.userEducationalStageToken = data.userEducationalStageToken ?? "";
      this.blackListOfProductToken = data.blackListOfProductToken ?? "";
      this.whiteListOfProductToken = data.whiteListOfProductToken ?? "";
      this.userTypeToken = data.userTypeToken ?? "";
      this.token = data.token ?? "";

      // } else {
      //   this.setInitialValue();
    }
  }
}
