import defaultImg from "@/assets/images/users.svg";
import UserProfile from "./UserProfile";

export default class UserModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    // actions fields
    this.mediaFile = "";
    this.establishmentRoleToken = "";
    this.userTypeToken = "";
    this.userNameAr = "";
    this.userNameEn = "";
    this.userNameUnd = "";
    this.userPassword = "";
    this.userPhoneCountryCode = "";
    this.userPhoneCountryCodeName = "";
    this.userPhone = "";
    this.userPhoneWithCC = "";
    this.userEmail = "";
    this.userProfileData = new UserProfile();
    this.userChildren = [];
    this.userParentsData = [];
    // info fields
    this.userName = "";
    this.userTypeNameCurrent = "";
    this.fullCode = "";
    this.userToken = "";
    this.userActivityTypeToken = "";
    this.userActivityTypeNameCurrent = "";
    this.userNameCurrent = "";
    this.userImagePath = "";
    this.userImageIsDefault = false;
    this.userImageSizeBytes = 0;
    this.userImageSizeTextCurrent = "";
  }
  fillData(data) {
    if (data) {
      this.mediaFile = "";
      this.establishmentRoleToken = data.establishmentRoleToken ?? "";
      this.userTypeToken = data.userTypeToken ?? "";
      this.userNameAr = data.userNameAr ?? "";
      this.userNameEn = data.userNameEn ?? "";
      this.userNameUnd = data.userNameUnd ?? "";
      this.userPassword = data.userPassword ?? "";
      this.userPhoneCountryCode = data.userPhoneCountryCode ?? "";
      this.userPhoneCountryCodeName = data.userPhoneCountryCodeName ?? "";
      this.userPhone = data.userPhone ?? "";
      this.userPhoneWithCC = data.userPhoneWithCC ?? "";
      this.userEmail = data.userEmail ?? "";
      this.userProfileData.fillData(data.userProfileData);
      this.userChildren = data.userChildren ?? [];
      this.userParentsData = data.userParentsData ?? [];
      this.userName = data.userName ?? "";
      this.userTypeNameCurrent = data.userTypeNameCurrent ?? "";
      this.fullCode = data.fullCode ?? "";
      this.userToken = data.userToken ?? "";
      this.userActivityTypeToken = data.userActivityTypeToken ?? "";
      this.userActivityTypeNameCurrent = data.userActivityTypeNameCurrent ?? "";
      this.userNameCurrent = data.userNameCurrent ?? "";
      this.userImagePath = data.userImagePath ?? "";
      this.userImageIsDefault = data.userImageIsDefault ?? false;
      this.userImageSizeBytes = data.userImageSizeBytes ?? 0;
      this.userImageSizeTextCurrent = data.userImageSizeTextCurrent ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
