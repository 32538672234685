import BaseEntityModelFilter from "./../../../general/BaseEntityModelFilter";
import store from "./../../../../store";

export default class ProductsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.categoryInfoDataInclude = true;
    this.productToken = "";
    this.categoryToken = "";
    this.blackListUserToken = "";
    this.whiteListUserToken = "";
    this.productPriceFrom = "";
    this.productPriceTo = "";
    this.activationTypeTokens =
      store.getters.generalSetting.products.activationTypeTokens || [];
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.categoryInfoDataInclude = data.categoryInfoDataInclude ?? true;
      this.productToken = data.productToken ?? "";
      this.categoryToken = data.categoryToken ?? "";
      this.blackListUserToken = data.blackListUserToken ?? "";
      this.whiteListUserToken = data.whiteListUserToken ?? "";
      this.productPriceFrom = data.productPriceFrom ?? "";
      this.productPriceTo = data.productPriceTo ?? "";
      this.activationTypeTokens = data.activationTypeTokens ?? [];
    } else {
      this.setInitialValue();
    }
  }
}
